import { nodesFromString } from "../tools/NodeFromString";
import axios from "axios";
import { notify } from './Notify'
function initBlock(blockContainer) {
    if (blockContainer.dataset.classname && blockContainer.dataset.url) {
            let listItems = blockContainer.querySelector(`.${blockContainer.dataset.classname}-list`),
                addButton = blockContainer.querySelector(`.${blockContainer.dataset.classname}-create`),
                currentItemsCount = listItems.querySelectorAll(`.${blockContainer.dataset.classname}`).length,
                trackSelector = $('#track-selector');

            function initItem(item) {
                item.querySelector(`.${blockContainer.dataset.classname}-delete`)?.addEventListener('click', () => item.remove())
            }

            function createItem() {
                if (trackSelector.val().length > 0) {
                    axios.get(blockContainer.dataset.url, {params: {index: currentItemsCount, ids: trackSelector.val()}})
                        .then(result => {
                            let response = result.data;
                            if (response.success) {
                                nodesFromString(response.data).forEach(item => {
                                    const trackInput = item.querySelector('.track-input').value;
                                    var itemExists= document.querySelector(`.track-input[value="${trackInput}"]`);
                                    if (!itemExists) {
                                        initItem(item);
                                        listItems.append(item);
                                    }
                                });
                                trackSelector.val(null).trigger('change');
                                currentItemsCount += trackSelector.val().length
                            }
                        });
                } else {
                    notify('Ошибка', 'danger', 'Выберите треки, которые нужно добавить');
                }
            }

            listItems.querySelectorAll(`.${blockContainer.dataset.classname}`).forEach(item => initItem(item))
            addButton.addEventListener('click', () => createItem())
    } else {
        if (!blockContainer.dataset.classname) {
            console.error('Не заполнен параметр data-classname у контейнера блоков')
        }
        if (!blockContainer.dataset.url) {
            console.error('Не заполнен параметр data-url у контейнера блоков')
        }
    }
}

export {initBlock}
